import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import PlatSelectionList from "../../common/selection/PlatSelectionList";
import { loadDocuments, setSelectedSearchType, SearchByLegalDescriptionKey, SearchByLegalDescriptionUrl } from "../../../redux/actions/searchActions";
import { validateDates } from "../../../utils/DateValidation";
import { LOADING_STATUES, DIRECTION_OPTIONS } from "../../constants/constants";

import moment from 'moment';

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

const componentName = SearchByLegalDescriptionKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByLegalDescription: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByLegalDescriptionUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {
  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  let fromRecordedDate = previousSearchKeyValue.fromRecordedDate;
  if (!fromRecordedDate && state.countyConfigNotableDatesReducer.showNotableDates === true) {
    fromRecordedDate = state.landlinkConfigReducer.abstractIndexBeginDate;
  } 
  
  if (!fromRecordedDate) {
    fromRecordedDate = moment('01/01/1900').format('L');
  }

  let toRecordedDate = previousSearchKeyValue.toRecordedDate;
  if (!toRecordedDate && state.countyConfigNotableDatesReducer.useVerificationDate === true) {
    toRecordedDate = state.landlinkConfigReducer.abstractVerifiedDate;
  }
  
  if (!toRecordedDate) {
    toRecordedDate = moment().format('L');
  }

  return {
    notableDates: state.notableDatesReducer,
    showNotableDates: state.countyConfigNotableDatesReducer.showNotableDates,
    useVerificationDate: state.countyConfigNotableDatesReducer.useVerificationDate,
    abstractIndexBeginDate: state.landlinkConfigReducer.abstractIndexBeginDate,
    abstractVerifiedDate: state.landlinkConfigReducer.abstractVerifiedDate,
    isPropertyCode: state.landlinkConfigReducer.propertyCode,
    allOnlySearch: state.countyConfigSearch2Reducer.allOnlySearch,
    documentType: previousSearchKeyValue.documentType ? previousSearchKeyValue.documentType : 'ALL',
    documentTypes: state.documentTypesReducer.codeDescriptionResultList,
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    instrumentGroups: state.instrumentGroupsReducer.codeDescriptionResultList,
    fromRecordedDate,
    toRecordedDate,
    instrumentGroup: previousSearchKeyValue.instrumentGroup ? previousSearchKeyValue.instrumentGroup : "ALL",
    legalType: previousSearchKeyValue.legalType ? previousSearchKeyValue.legalType : 'LB',
    searchMethod: previousSearchKeyValue.searchMethod ? previousSearchKeyValue.searchMethod : 'CONTAINS',
    section: previousSearchKeyValue.section ? previousSearchKeyValue.section : null,
    township: previousSearchKeyValue.township ? previousSearchKeyValue.township : null,
    range: previousSearchKeyValue.range ? previousSearchKeyValue.range : null,
    base: previousSearchKeyValue.base ? previousSearchKeyValue.base : null,
    meridian: previousSearchKeyValue.meridian ? previousSearchKeyValue.meridian : null,
    qqqq: previousSearchKeyValue.qqqq ? previousSearchKeyValue.qqqq : null,
    qqq: previousSearchKeyValue.qqq ? previousSearchKeyValue.qqq : null,
    qq: previousSearchKeyValue.qq ? previousSearchKeyValue.qq : null,
    q: previousSearchKeyValue.q ? previousSearchKeyValue.q : null,
    municipality: previousSearchKeyValue.municipality ? previousSearchKeyValue.municipality : null,
    otherLotLb: previousSearchKeyValue.otherLotLb ? previousSearchKeyValue.otherLotLb : null,
    otherLotMb: previousSearchKeyValue.otherLotMb ? previousSearchKeyValue.otherLotMb : null,
    otherLotCodeLb: previousSearchKeyValue.otherLotCodeLb ? previousSearchKeyValue.otherLotCodeLb : null,
    otherLotCodeMb: previousSearchKeyValue.otherLotCodeMb ? previousSearchKeyValue.otherLotCodeMb : null,
    lot: previousSearchKeyValue.lot ? previousSearchKeyValue.lot : null,
    sublot: previousSearchKeyValue.sublot ? previousSearchKeyValue.sublot : null,
    block: previousSearchKeyValue.block ? previousSearchKeyValue.block : null,
    plat: previousSearchKeyValue.plat ? previousSearchKeyValue.plat : null,
    platNumber: previousSearchKeyValue.plat ? previousSearchKeyValue.plat : null,
    platName: previousSearchKeyValue.platName ? previousSearchKeyValue.platName : null,
    building: previousSearchKeyValue.building ? previousSearchKeyValue.building : null,
    unit: previousSearchKeyValue.unit ? previousSearchKeyValue.unit : null,
    garage: previousSearchKeyValue.garage ? previousSearchKeyValue.garage : null,
    grantorGranteeLastNameLb: previousSearchKeyValue.grantorGranteeLastNameLb ? previousSearchKeyValue.grantorGranteeLastNameLb : null,
    grantorGranteeLastNameMb: previousSearchKeyValue.grantorGranteeLastNameMb ? previousSearchKeyValue.grantorGranteeLastNameMb : null,
    grantorGranteeFirstNameLb: previousSearchKeyValue.grantorGranteeFirstNameLb ? previousSearchKeyValue.grantorGranteeFirstNameLb : null,
    grantorGranteeFirstNameMb: previousSearchKeyValue.grantorGranteeFirstNameMb ? previousSearchKeyValue.grantorGranteeFirstNameMb : null,
    grantorGranteeMiddleInitialLb: previousSearchKeyValue.grantorGranteeMiddleInitialLb ? previousSearchKeyValue.grantorGranteeMiddleInitialLb : null,
    grantorGranteeMiddleInitialMb: previousSearchKeyValue.grantorGranteeMiddleInitialMb ? previousSearchKeyValue.grantorGranteeMiddleInitialMb : null,
    otherLotCodes: state.otherLotCodesReducer.codeDescriptionResultList,
    isQQQQ: state.landlinkConfigReducer.qqqq,
    isQQQ: state.landlinkConfigReducer.qqq,
    isBaseline: state.landlinkConfigReducer.baseline,
    isMunicipality: state.landlinkConfigReducer.municipality,
    isMeridian: state.landlinkConfigReducer.meridian,
    isMbOtherLot: state.landlinkConfigReducer.mbOtherLot,
    isSublot: state.landlinkConfigReducer.sublot,
    allPlatList: state.platsReducer.codeDescriptionResultList,
    allPlatNameList: state.platNamesReducer.codeDescriptionResultList,
    allPlatNumberList: state.platNumbersReducer.codeDescriptionResultList
  };
}

class SearchByLegalDescription extends Component {
  state = {
    documentType: this.props.documentType,
    fromRecordedDate: this.props.fromRecordedDate,
    toRecordedDate: this.props.toRecordedDate,
    legalType: this.props.legalType,
    searchMethod: this.props.searchMethod,
    section: this.props.section,
    township: this.props.township,
    range: this.props.range,
    base: this.props.base,
    meridian: this.props.meridian,
    qqqq: this.props.qqqq,
    qqq: this.props.qqq,
    qq: this.props.qq,
    q: this.props.q,
    municipality: this.props.municipality,
    otherLotMb: this.props.otherLotMb,
    otherLotLb: this.props.otherLotLb,
    otherLotCodeMb: this.props.otherLotCodeMb,
    otherLotCodeLb: this.props.otherLotCodeLb,
    lot: this.props.lot,
    sublot: this.props.sublot,
    block: this.props.block,
    plat: this.props.plat,
    platName: this.props.platName,
    platNumber: this.props.platNumber,
    building: this.props.building,
    unit: this.props.unit,
    garage: this.props.garage,
    instrumentGroup: this.props.instrumentGroup,
    grantorGranteeLastNameLb: this.props.grantorGranteeLastNameLb,
    grantorGranteeLastNameMb: this.props.grantorGranteeLastNameMb,
    grantorGranteeFirstNameLb: this.props.grantorGranteeFirstNameLb,
    grantorGranteeFirstNameMb: this.props.grantorGranteeFirstNameMb,
    grantorGranteeMiddleInitialLb: this.props.grantorGranteeMiddleInitialLb,
    grantorGranteeMiddleInitialMb: this.props.grantorGranteeMiddleInitialMb,

    showPlatNumberDropdown: false,
    platNumberFilteredList: this.props.allPlatNumberList,
    showPlatNameDropdown: false,
    platNameFilteredList: this.props.allPlatNameList,
    showPlatList: false,

    loadingStatus: "",
    submitMessage: '',

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {

    let targetValue = event.target.value;    
         if ((event.target.id !== 'platName' && event.target.id !== 'platNumber') || !this.state.plat) {
      this.setState({
        errors: {},
        submitMessage: '',
        [event.target.id]: targetValue
      })
    }
    
    if (event.target.id === 'platNameSelect') {
      let filteredList = this.props.allPlatList.filter(function (plat) {
        return plat.code == targetValue;
      });
      this.setState({
        showPlatNameDropdown: false,
        showPlatNumberDropdown: false,
        platNumber: targetValue,
        platName: filteredList[0].description,
        // platFound: true,
        plat: targetValue
      })
    } else if (event.target.id === 'platName' && !this.state.plat) {
      let filteredList = this.props.allPlatNameList.filter(function (plat) {
        return plat.description.toUpperCase().startsWith(targetValue.toUpperCase())
      });

      if (filteredList.length === 0) {
        let errors = {};
        errors["platName"] = "No matching plat found.";
        this.setState({
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat: '',
          errors: errors
        })
      } else if (filteredList.length === 1) {
        let namefilteredList = this.props.allPlatList.filter(function (plat) {
          return plat.code == filteredList[0].code;
        });
        this.setState({
          platNumber: filteredList[0].code,
          platName: namefilteredList[0].description,
          platNameFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          // platFound: true,
          plat: filteredList[0].code
        })
      } else if (targetValue.length >= 2) {
        filteredList = [{code: "-1", description: "(Click here to select a plat)"}].concat(filteredList);
      
        this.setState({
          platNameFilteredList: filteredList,
          showPlatNameDropdown: true,
          showPlatNumberDropdown: false,
          plat:''
        })
      } else if (targetValue.length < 2) {
        this.setState({
          platNameFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat:''
        })
      }
    } else if (event.target.id === 'platNumberSelect') {
      let filteredList = this.props.allPlatList.filter(function (plat) {
        return plat.code == targetValue;
      });
      this.setState({
        showPlatNameDropdown: false,
        showPlatNumberDropdown: false,
        platNumber: targetValue,
        platName: filteredList[0].description,
        // platFound: true,
        plat: targetValue
      })
    } else if (event.target.id === 'platNumber' && !this.state.plat) {
      let filteredList = this.props.allPlatNumberList.filter(function (plat) {
        return plat.code.toUpperCase().startsWith(targetValue.toUpperCase())
      });

      if (filteredList.length === 0) {
        let errors = {};
        errors["platNumber"] = "No matching plat found.";
        this.setState({
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat:'',
          errors: errors
        })
      } else if (filteredList.length === 1) {
        let namefilteredList = this.props.allPlatList.filter(function (plat) {
          return plat.code == filteredList[0].code;
        });
        this.setState({
          platNumber: filteredList[0].code,
          platName: namefilteredList[0].description,
          platNumberFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat: filteredList[0].code
        })
      } else if (targetValue.length >= 2) {
        filteredList = [{code: "-1", description: "(Click here to select a plat)"}].concat(filteredList);
      
        this.setState({
          platNumberFilteredList: filteredList,
          showPlatNumberDropdown: true,
          showPlatNameDropdown: false,
          plat:''
        })
      } else if (targetValue.length < 2) {
        this.setState({
          platNumberFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat:''
        })
      }
    }

  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { platNameFilteredList, platNumberFilteredList, loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByLegalDescription(request, this.props.history, this.hideLoading);
  }

  handleValidation = () => {
    let errors = {};

    if (this.state.legalType === 'LB') {
        if (!this.state.plat) {
        errors["platNumber"] = "A valid plat required.";
        this.setState({ errors: errors });
        return false;
      }

      if (!this.state.grantorGranteeLastNameLb && this.state.grantorGranteeFirstNameLb) {
        errors["grantorGranteeLastNameLb"] = "Required if First Name entered.";
        this.setState({ errors: errors });
        return false;
      }

      if (!this.state.grantorGranteeLastNameLb && this.state.grantorGranteeMiddleInitialLb) {
        errors["grantorGranteeLastNameLb"] = "Required if MI entered.";
        this.setState({ errors: errors });
        return false;
      }

      if (!this.state.grantorGranteeFirstNameLb && this.state.grantorGranteeMiddleInitialLb) {
        errors["grantorGranteeFirstNameLb"] = "Required if MI entered.";
        this.setState({ errors: errors });
        return false;
      }
    } else {
      if (!this.state.grantorGranteeLastNameMb && this.state.grantorGranteeFirstNameMb) {
        errors["grantorGranteeLastNameMb"] = "Required if First Name entered.";
        this.setState({ errors: errors });
        return false;
      }

      if (!this.state.grantorGranteeLastNameMb && this.state.grantorGranteeMiddleInitialMb) {
        errors["grantorGranteeLastNameMb"] = "Required if MI entered.";
        this.setState({ errors: errors });
        return false;
      }

      if (!this.state.grantorGranteeFirstNameMb && this.state.grantorGranteeMiddleInitialMb) {
        errors["grantorGranteeFirstNameMb"] = "Required if MI entered.";
        this.setState({ errors: errors });
        return false;
      }
    }

    let formIsValid = validateDates(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();

    let fromRecordedDate = moment('01/01/1900').format('L');
    if (this.props.showNotableDates === true) {
      fromRecordedDate = this.props.abstractIndexBeginDate;
    } 
  
    let toRecordedDate = moment().format('L');
    if (this.props.useVerificationDate === true) {
      toRecordedDate = this.props.abstractVerifiedDate;
    }

    this.setState({
      documentType: 'ALL',
      fromRecordedDate,
      toRecordedDate,
      section: '',
      township: '',
      range: '',
      base: '',
      meridian: '',
      qqqq: '',
      qqq: '',
      qq: '',
      q: '',
      municipality: '',
      otherLotMb: '',
      otherLotLb: '',
      otherLotCodeMb: '',
      otherLotCodeLb: '',
      lot: '',
      sublot: '',
      block: '',
      plat: '',
      platNumber: '',
      platName: '',
      showPlatNumberDropdown: false,
      platNumberFilteredList: this.props.allPlatNumberList,
      showPlatNameDropdown: false,
      platNameFilteredList: this.props.allPlatNameList,
      showPlatList: false,
      building: '',
      unit: '',
      garage: '',
      instrumentGroup: 'ALL',
      grantorGranteeLastNameLb: '',
      grantorGranteeLastNameMb: '',
      grantorGranteeFirstNameLb: '',
      grantorGranteeFirstNameMb: '',
      grantorGranteeMiddleInitialLb: '',
      grantorGranteeMiddleInitialMb: '',
      submitMessage: '',
      errors: {}
    })
  }

  handleDatePickerChangeFromRecordedDate = (date) => {
    this.setState({
      fromRecordedDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToRecordedDate = (date) => {
    this.setState({
      toRecordedDate: moment(date).format('L')
    })
  }

  handlePlatLookup = () => {
    this.setState({
      showPlatList: true
    });
  }

  handlePlatReset = () => {
    this.setState({
      plat: '',
      platName: '',
      platNumber: '',
      showPlatNumberDropdown: false,
      platNumberFilteredList: this.props.allPlatNumberList,
      showPlatNameDropdown: false,
      platNameFilteredList: this.props.allPlatNameList,
      showPlatList: false,
      errors: {}
    });
  }

  handleClosePlatLookup = (selectedId, selectedName) => {
  
    this.setState({
      errors: {},
      showPlatList: false
    })

    if (selectedId) {
      this.setState({
        plat: selectedId,
        platNumber: selectedId,
        platName: selectedName
      })
    }
  }

  render() {
    var hideBaseMeridianMunicipalityRow = this.state.legalType !== 'MB' || (this.props.isBaseline === false && this.props.isMunicipality === false && this.props.isMeridian === false)

    let formGroupRows = [
      {
        heading: 'Legal Description',
        formGroups: [{ id: 'legalType', label: 'Legal Type', fieldType: 'select', primaryCriteria: true, value: this.state.legalType, options: [{ code: 'LB', description: 'Lots & Blocks' }, { code: 'MB', description: 'Metes & Bounds' }] },
        { id: 'searchMethod', label: '', fieldType: 'select', primaryCriteria: true, value: this.state.searchMethod, options: [{ code: 'CONTAINS', description: 'Contains' }, { code: 'EXACT', description: 'Matches Exactly' }] }]
      },
      {
        formGroups: [{ id: 'section', label: 'Section', fieldType: 'text', hideRow: this.state.legalType !== 'MB', value: this.state.section },
        { id: 'township', label: 'Township', fieldType: 'text', primaryCriteria: true, required: this.state.legalType === 'MB', value: this.state.township },
        { id: 'range', label: 'Range', fieldType: 'text', primaryCriteria: true, required: this.state.legalType === 'MB', value: this.state.range }]
      },
      {
        formGroups: [{ id: 'qqqq', label: 'QQQQ', fieldType: 'select', options: DIRECTION_OPTIONS, hideRow: this.state.legalType !== 'MB', hideField: this.props.isQQQQ === false, value: this.state.qqqq },
        { id: 'qqq', label: 'QQQ', fieldType: 'select', options: DIRECTION_OPTIONS, hideField: this.props.isQQQ === false, value: this.state.qqq },
        { id: 'qq', label: 'QQ', fieldType: 'select', options: DIRECTION_OPTIONS, value: this.state.qq },
        { id: 'q', label: 'Q', fieldType: 'select', options: DIRECTION_OPTIONS, value: this.state.q }]
      },
      {
        formGroups: [{ id: 'base', label: 'Base', fieldType: 'text', hideRow: hideBaseMeridianMunicipalityRow, hideField: this.props.isBaseline === false, value: this.state.base },
        { id: 'meridian', label: 'Meridian', fieldType: 'text', hideField: this.props.isMeridian === false, value: this.state.meridian },
        { id: 'municipality', label: 'Municipality', fieldType: 'text', hideField: this.props.isMunicipality === false, value: this.state.municipality }]
      },
      {
        formGroups: [
          { id: 'platNumber', label: 'Plat Number', primaryCriteria: true, options: this.state.platNumberFilteredList, style: {minWidth: 100}, fieldType: 'plat', required: this.state.legalType === 'LB', hideRow: this.state.legalType === 'MB', hideSelect: this.state.showPlatNumberDropdown === false, value: this.state.platNumber, error: this.state.errors['platNumber'] },
          { id: 'platName', label: 'Plat Name', primaryCriteria: true, options: this.state.platNameFilteredList, style: {minWidth: 300}, fieldType: 'plat', required: this.state.legalType === 'LB', hideRow: this.state.legalType === 'MB', hideSelect: this.state.showPlatNameDropdown === false, value: this.state.platName, error: this.state.errors['platName']  },
          { id: 'platResetButton', label: 'Reset', fieldType: 'reset', onClick: this.handlePlatReset },
          { id: 'platLookupButton', fieldType: 'lookupWithLabel', style: {marginLeft: 8}, label: 'Full List', onClick: this.handlePlatLookup }
        ]
      },
      {
        formGroups: [{ id: 'lot', label: 'Lot', fieldType: 'text', hideRow: this.state.legalType === 'MB', hideField: this.state.legalType === 'MB', value: this.state.lot },
        { id: 'block', label: 'Block', fieldType: 'text', hideField: this.state.legalType === 'MB', value: this.state.block },
        { id: 'sublot', label: 'Sublot', fieldType: 'text', hideField: this.state.legalType === 'MB' || this.props.isSublot === false, value: this.state.sublot }]
      },
      {
        formGroups: [{ id: 'otherLotCodeLb', label: 'Other Lot Type', fieldType: 'select', hideRow: this.state.legalType === 'MB' || this.props.isMbOtherLot === false, options: this.props.otherLotCodes, value: this.state.otherLotCodeLb },
        { id: 'otherLotLb', label: 'Other Lot', fieldType: 'text', value: this.state.otherLotLb }]
      },
      {
        formGroups: [{ id: 'otherLotCodeMb', label: 'Other Lot Type', fieldType: 'select', hideRow: this.state.legalType !== 'MB' || this.props.isMbOtherLot === false, options: this.props.otherLotCodes, value: this.state.otherLotCodeMb },
        { id: 'otherLotMb', label: 'Other Lot', fieldType: 'text', value: this.state.otherLotMb }]
      },
      {
        formGroups: [{ id: 'building', label: 'Building', fieldType: 'text', hideRow: this.state.legalType === 'MB', value: this.state.building },
        { id: 'unit', label: 'Unit', fieldType: 'text', value: this.state.unit },
        { id: 'garage', label: 'Garage', fieldType: 'text', value: this.state.garage }]
      }
    ]

    if (this.props.isPropertyCode === true && this.props.allOnlySearch !== true && this.props.allOnlySearch !== 'true') {
      formGroupRows.push({
        formGroups: [{ id: 'documentType', label: 'Document Type', fieldType: 'select', value: this.state.documentType, options: this.props.documentTypes } , { id: 'instrumentGroup', label: 'Instrument Group', fieldType: 'select', options: this.props.instrumentGroups, value: this.state.instrumentGroup }]
      });
    } else {   
      formGroupRows.push({
        formGroups: [{ id: 'instrumentGroup', label: 'Instrument Group', fieldType: 'select', options: this.props.instrumentGroups, value: this.state.instrumentGroup }]
      });
    }

    formGroupRows.push({
      formGroups: [{ id: 'fromRecordedDate', label: 'From Date', fieldType: 'date', value: this.state.fromRecordedDate, handleDatePickerChange: this.handleDatePickerChangeFromRecordedDate, error: this.state.errors['fromRecordedDate'] },
      { id: 'toRecordedDate', label: 'To Date', fieldType: 'date', value: this.state.toRecordedDate, handleDatePickerChange: this.handleDatePickerChangeToRecordedDate, error: this.state.errors['toRecordedDate'] }]
    });

    formGroupRows.push({
      heading: 'Grantor/Grantee (Optional)',
      formGroups: [{ id: 'grantorGranteeLastNameLb', label: 'Business or Last Name', fieldType: 'text', hideRow: this.state.legalType === 'MB', value: this.state.grantorGranteeLastNameLb, error: this.state.errors['grantorGranteeLastNameLb'] },
      { id: 'grantorGranteeFirstNameLb', label: 'First Name', fieldType: 'text', value: this.state.grantorGranteeFirstNameLb, error: this.state.errors['grantorGranteeFirstNameLb'] },
      { id: 'grantorGranteeMiddleInitialLb', label: 'Middle Initial', fieldType: 'text', maxLength: 1, value: this.state.grantorGranteeMiddleInitialLb, error: this.state.errors['grantorGranteeMiddleInitialLb'] }]
    });

    formGroupRows.push({
      formGroups: [{ id: 'grantorGranteeLastNameMb', label: 'Business or Last Name', fieldType: 'text', hideRow: this.state.legalType !== 'MB', value: this.state.grantorGranteeLastNameMb, error: this.state.errors['grantorGranteeLastNameMb'] },
      { id: 'grantorGranteeFirstNameMb', label: 'First Name', fieldType: 'text', value: this.state.grantorGranteeFirstNameMb, error: this.state.errors['grantorGranteeFirstNameMb'] },
      { id: 'grantorGranteeMiddleInitialMb', label: 'Middle Initial', fieldType: 'text', maxLength: 1, value: this.state.grantorGranteeMiddleInitialMb, error: this.state.errors['grantorGranteeMiddleInitialMb'] }]
    });

    const formProps = {
      componentName: componentName,
      pageHeading: "Search by Legal Description",
      autoComplete: false,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      formGroupRows: formGroupRows,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,
      
      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }
    return (
      <>
        <FormTemplate {...formProps} />
        <PlatSelectionList showModal={this.state.showPlatList} onHide={(selectedId, selectedName) => this.handleClosePlatLookup(selectedId, selectedName)} />
      </>
    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchByLegalDescription);